.menu {
  display: flex;
  align-items: center;
  background-color: #fdfdfd;
  border-bottom: #333;
  box-shadow: -1px 50px 5px -6px rgba(96, 96, 96, 0.75);

}

.menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: auto;

}

.menu-list li {
  margin-right: 10px;
}

.menu-list li:last-child {
  margin-right: 0;
}

.menu-list li a {
  text-decoration: none;
  color: #333;
  padding: 5px 10px;
}

.div-main-foother {
  display: block;
}

.div-user-button {
  display: block;
}

.div-steps {
  width: 100%;
  display: block;
}

.div-steps-movil {
  display: none;
  min-width: 250px;
}

.img-fluid {
  display: block;
}

.img-fluid-tiny {
  display: none;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: -15px;
}

.component-avatar {
  margin-top: 0;
}

.form-steps {
  max-width: 500px;
  margin-left: -23px;
}

.input-steps {
  width: 485px;
}

.slider-steps {
  width: 420px;
}

.input-steps-cols {
  width: 200px;
  margin-left: 30px;
}

.select-steps-cols {
  width: 200px;
}

.div-form-hidden {
  display: block;
}

.title-steps {
  font-size: 39px;
}

.title-steps-revision {
  font-size: 39px;
}


.title-steps-buro {
  font-size: 39px;
}

.title-steps-pld-1 {
  font-size: 39px;
}

.subtitle-steps {
  font-size: 22px;
  font-weight: bold;
  color: "#4f4f4f";
}

.radiobutton-steps {
  font-size: 18px;
}

.div-buttons {
  width: 100%;
}

.button-forward {
  width: 480px;
  margin-left: 25px;
}

.buttons-back-forward {
  width: 230px;
}

.select-steps {
  width: 485px;
  margin-left: 20px;
}

.row-3-cols {
  margin-left: 28px;
  width: 470px;
}

.title-steps-pld {
  font-size: 17px;
}

.radio-option {
  font-size: 16px;
  width: 25px;
  text-align: left;
}

.div-frame-satws {
  width: 500px;
}

.div-buttons-steps-buro {
  width: 550px;
}

.img-revision {
  width: 500px;
}

.subtitle-steps-revision {
  font-size: 20px;
  width: 800px;
}



.search-steps-cols{
  width: 200px;
  margin-left: 30px;
}

.search-steps-cols-aval{
  width: 233px;
  margin-left: 0px;
}


.div-productos{
  margin-right: 30px;  
}


@media screen and (max-width:1200px) {
  .div-user-button {
    display: none;
  }

  .div-steps {
    width: 70%;
  }

  .img-fluid {
    display: block;
  }
}

@media screen and (max-width:1000px) {
  .div-steps {
    width: 65%;
  }

  .img-fluid {
    display: block;
  }
}

@media screen and (max-width:900px) {
  .div-steps {
    width: 60%;
  }

  .img-fluid {
    display: block;
  }
}

@media screen and (max-width:800px) {
  .div-steps {
    display: none;
  }

  .div-steps-movil {
    display: block;
  }

  .img-fluid {
    display: block;
  }
}

@media screen and (max-width:600px) {
  .account-main-div {
    display: none;
  }

  .img-fluid {
    width: 170px;
    display: block;
  }

  .form-steps {
    max-width: 300px;
    margin-left: -0px;
  }

  .input-steps {
    width: 275px;
  }

  .slider-steps {
    width: 255px;
  }

  .div-form-hidden {
    display: none;
  }

  .title-steps {
    font-size: 25px;
  }

  .subtitle-steps {
    font-size: 19px;
    font-weight: bold;
  }


  .radiobutton-steps {
    font-size: 12px;
  }

  .input-steps-cols {
    width: 110px;
    margin-left: 10px;
  }

  .div-buttons {
    width: 90%;
  }

  .buttons-back-forward {
    width: 110px;
  }

  .select-steps {
    width: 250px;
    margin-left: 10px;
  }

  .select-steps-cols {
    width: 900px;
  }

  .button-forward {
    width: 250px;
    margin-left: 10px;
  }

  .row-3-cols {
    margin-left: 15px;
    width: 280px;
  }

  .title-steps-pld {
    font-size: 12px;
    width: 250px;
  }

  .title-steps-pld-1 {
    font-size: 14px;
    width: 300px;
  }

  .title-steps-buro {
    font-size: 18px;
  }

  .radio-option {
    font-size: 12px;
    width: 15px;
    text-align: left;
  }

  .div-frame-satws {
    width: 260px;
  }

  .div-buttons-steps-buro {
    width: 350px;
  }

  .title-steps-revision {
    font-size: 22px;
  }

  .img-revision {
    width: 280px;
  }

  .subtitle-steps-revision {
    font-size: 14px;
    width: 280px;
  }

  .search-steps-cols{
    width: 110px;
    margin-left: 10px;
  }

  .div-productos{
    margin-left: 130px;
    margin-right: 0px;
  }
}

@media screen and (max-height: 600px) {

  .div-main-foother {
    display: none;
  }

}


@media screen and (max-width:500px) {
  .img-fluid {
    display: none;
  }

  .img-fluid-tiny {
    display: block;
  }

  .component-avatar {
    margin-top: 10;
  }

}


@media screen and (max-width:380px) {
  .img-fluid-tiny {
    display: none;
  }

}