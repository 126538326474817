.form-steps-empresa{
    max-width: 500px;
    margin-left: -71px;
  }

  @media screen and (max-width:600px) {
    .form-steps-empresa{
        max-width: 300px;
        margin-left: -23px;
      }
  }