

@font-face {
  font-family: 'Red Hat Display';
  src: url('./fonts/RedHatDisplay-VariableFont_wght.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Red Hat Display', sans-serif;
}

.App{
  font-family: 'Red Hat Display', sans-serif;
}