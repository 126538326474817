.account-welcome-btn-login{
    width: 200px;
}

.account-welcome-btn-create{
    width: 200px;
}

.div-carousel{
  display: block;
}


@media screen and (max-width: 800px) {
    .account-welcome-btn-login{
        width: 150px;
    }
    
    .account-welcome-btn-create{
        width: 150px;
    }
    
  }

  @media screen and (max-width: 600px) {
    .div-carousel{
      display: none;
    }
  }



  /* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b6b6b6; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7e7b7b; 
  }