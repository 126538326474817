.CollapseSolicitudes{
  width: 100%;
}

.dtAvatarNumber {
  padding-left: 150px;
}

.dt {
  float: left;
  width: 65%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 17px;
}
.dd {
  float: left;
  width: 100px;
  overflow: hidden;
}

.dt span:after {
  content: ' - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ';
  font-size: 20px;
}

.dt-documentos {
  float: left;
  overflow: hidden;
  white-space: nowrap;
  font-size: 17px;
  margin-left: 150px;
}

.dt-documentos-puntos {
  float: left;
  max-width: 845px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 17px;
  margin-left: 0px;
}

.dt-documentos-puntos span:after {
  content: ' - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ';
  
  font-size: 20px;
}

.dd-documentos {
  float: left;
  width: 100px;
  overflow: hidden;
}

.PanelHeader {
  font-size: 26px;
  font-weight: bold;
  margin-left: 150px;
  margin-bottom: 0px;
  margin-top: -30px;
}

.ItemName {
  font-size: 20px;
}

.IconNumber {
  font-size: 21px;
  color: #4c4cbc;
  margin-right: 5px;
  padding-bottom: -10px;
}

.CollapseHeader {
  font-size: 16px;
}

.TitleSolicitudes {
  font-size: 38px;
  font-weight: 900;
  color: #4c4cbc;
  margin-bottom: 20px;
}

.IconCheck {
  color: #04bc74;
  font-size: 23px;
  margin-top: 6px;
}

.IconPending {
  color: #ffd30f;
  font-size: 23px;
  margin-top: 6px;
}

.btnDocumentos {
  font-size: 18px;
  width: 240px;
}

.title-tipo-documento{
  width: 140px;
  margin-left: -20px;
  margin-right: 30px;
}

.btnUploadFile{
    width: 220px;
    height: 40px;
    padding-top: 20px;
}

.lbl-title-message{
  color: #04bc74;
  font-weight: bold;
  font-size: 25px;
  margin-left: 50px;
}

.p-message{
  margin-left: 50px;
  margin-top: -5px;
}

.modal-pld{
  margin-top: -70px;
  max-height: 250px;
  min-width: 650px;
}

.div-title-panel{
  width: 85%;
}

.lbl-panel-status{
  margin-left: 0px;
}

.lbl-panel-producto{
  margin-right: 20px;
}

.lbl-panel-folio{
  margin-right: 20px;
}

@media screen and (max-width: 1800px) {
  .dt-documentos-puntos {
    max-width: 725px;
  }
}
@media screen and (max-width: 1700px) {
  .dt-documentos-puntos {
    max-width: 660px;
  }
}

@media screen and (max-width: 1500px) {
  .dt span:after {
    content: ' - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -';
    font-size: 20px;
  }

  .dt-documentos-puntos {
    max-width: 540px;
  }
}

@media screen and (max-width: 1400px) {
  .dt span:after {
    content: ' - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -';
    
    font-size: 20px;
  }

  .dt-documentos-puntos {
    max-width: 540px;
  }
}

@media screen and (max-width: 1350px) {
  .dt {
    width: 64%;
  }

  .dt span:after {
    content: ' - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ';
    font-size: 20px;
  }

  .dt-documentos-puntos {
    max-width: 450px;
  }
}

@media screen and (max-width: 1250px) {
  .dt {
    width: 55%;
  }

  .dt-documentos-puntos {
    max-width: 350px;
  }
}

@media screen and (max-width: 1150px) {
  .dt {
    width: 55%;
  }

  .dtAvatarNumber {
    padding-left: 100px;
  }

  .PanelHeader {
    margin-left: 100px;
  }

  .dt span:after {
    content: ' - - - - - - - - - - - - - - - - - - - - - - - - - ';
    
    font-size: 23px;
  }

  .dt-documentos {
    margin-left: 100px;
  }

  .dt-documentos-puntos {
    max-width: 265px;
  }
}

@media screen and (max-width: 850px) {
  .dt {
    width: 35%;
  }

  .dt-documentos-puntos {
    max-width: 80px;
  }
}

@media screen and (max-width: 800px) {
  .TitleSolicitudes {
    font-size: 22px;
    font-weight: 900;
    color: #4c4cbc;
    margin-bottom: 10px;
  }

  .CollapseHeader {
    font-size: 12px;
  }

  .PanelHeader {
    font-size: 18px;
    font-weight: bold;
    margin-left: 0px;
    margin-bottom: 5px;
  }

  .dtAvatarNumber {
    padding-left: 0px;
  }

  .dt {
    width: 250px;
  }

  .dd {
    float: left;
    width: 30px;
    overflow: hidden;
  }

  .ItemName {
    font-size: 10px;
  }

  .IconNumber {
    font-size: 17px;
  }

  .dt span:after {
    content: ' - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ';
    
    font-size: 12px;
  }

  .btnDocumentos {
    font-size: 10px;
    width: 160px;
  }

  .dt-documentos {
    margin-left: 0px;
  }

  .dt-documentos-puntos span:after {
    content: ' - - - - - - - - - - - - - - - - - - - - - - ';
    
    font-size: 12px;
  }

  .dt-documentos-puntos {
    max-width: 90px;
  }
}

@media screen and (max-width: 600px) {

  .CollapseSolicitudes{
    width: 365px;
  }

  .lbl-panel-producto{
    margin-right: 10px;
  }
  
  .lbl-panel-folio{
    margin-right: 0px;
  }

  .lbl-panel-status{
    margin-left: 30px;
  }
  
  

  .TitleSolicitudes {
    font-size: 22px;
    font-weight: 900;
    color: #4c4cbc;
    margin-bottom: 10px;
  }

  .CollapseHeader {
    font-size: 11px;
  }

  .PanelHeader {
    font-size: 18px;
    font-weight: bold;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .dtAvatarNumber {
    padding-left: 0px;
  }

  .dt {
    margin-left: 0px;
    width: 230px;
  }
  .dd {
    float: left;
    width: 30px;
    overflow: hidden;
  }

  .ItemName {
    font-size: 10px;
  }

  .IconNumber {
    font-size: 17px;
  }

  .dt span:after {
    content: ' - - - - - - - - - - - - - - - - - - - - - - - - - - - ';
    font-size: 12px;
  }

  .IconCheck {
    font-size: 16px;
  }

  .IconPending {
    font-size: 16px;
  }

  .title-tipo-documento{
    width: 100px;
    margin-left: -20px;
    margin-right: 30px;
  }

  .btnUploadFile{
    width: 110px;
    height: 35px;
    padding-top: 20px;
  }

  .lbl-title-message{
    color: #04bc74;
    font-weight: bold;
    font-size: 15px;
    margin-left: 10px;
  }

  .p-message{
    margin-left: 10px;
    margin-top: -5px;
  }

  .modal-pld{
    margin-top: -70px;
    min-width: 250px;
  }
}
