.div-subtitle-documentos {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-left: 0px;
  }

  .div-subtitle-documentos-1{
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-left: 0px;
  }

  .div-buttons-documentos {
    width: 550px;
  }

  @media screen and (max-width:600px) {
    .div-buttons-documentos {
        width: 100%;
        margin-left: -20px;
      }

      .div-subtitle-documentos-1{
        margin-top: 450px;
      }
  }