.select-steps-clientes {
    width: 250px;
    margin-left: 0px;
  }

  .select-steps-ocupacion{
    width: 485px;
  }

  .lblOptionCombOcupacion{
    width: 400px;
  }

  .lblOptionCombOnacionalidad{
    width: 400px;
    text-align: left;
  }

  .tooltip-ocupacion{
    margin-left: -100px;
    width: 500px;
    margin-top: -50px;
  }
  
  
  @media screen and (max-width:600px) {
    .search-steps-cols-aval{
      width: 130px;
      min-width: 100px;
    }

    .select-steps-ocupacion{
      width: 280px;
    }

    .lblOptionCombOcupacion{
      width: 280px;
    }
  }