.input-cambio{
    width: 450px;
}

@media screen and (max-width:600px) {
    .input-cambio{
        width: 300px;
        margin-left: 20px;
        margin-right: 20px;
    }
  
  }