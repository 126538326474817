/* VISTA CLIENTES */
.select-steps-clientes {
    width: 485px;
    margin-left: -0px;
    min-width: 200px;
}

.datepicker-clientes {
    width: 230px;
    margin-left: -0px;
}

.lblOptionCombo{
    text-align: left;
    width: 200px;
}

.ant-popover-buttons .ant-btn-primary.ant-btn {
    display: none;
  }

.img-revision{
    width: 400px;
}

.img-ine{
    width: 350px;
    height: 500px;
}

@media screen and (max-width:600px) {
    .select-steps-clientes {
        width: 250px;
        margin-left: 0px;
        min-width: 100px;
    }

    .datepicker-clientes {
        width: 120px;
        margin-left: -0px;
    }

    .img-revision{
        width: 300px;
        margin-left: -50px;
    }
}