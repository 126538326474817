.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la ventana */
  }
  
  .red-text {
    color: red; 
  }
  