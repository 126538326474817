.div-create-subtitle{
    font-size: 20px;
    display: block;
    margin-left: 100px;
}

.input-create{
    width: 220px;
}

.input-create-one{
    width: 450px;
    margin-left: 0px;
}

.input-create-one-password{
    width: 450px;
    margin-left: -3px;
}

.table-create{
    min-width: 600px;
}

.div-create-ejecutivo{
    font-size: 20px;
}

@media screen and (max-width:1000px) {
    .table-create{
        min-width: 480px;
    }

    .input-create{
        width: 200px;
    }

    .input-create-one{
        margin-left: 0px;
        width: 410px;
    }

    .input-create-one-password{
        margin-left: 0px;
        width: 410px;
    }
}

@media screen and (max-width:600px) {
    
    .div-create-subtitle{
        font-size: 20px;
        display: none;
        margin-left: 0px;
    }

    .input-create{
        width: 160px;
    }
    
    .input-create-one{
        margin-left: -3px;
        width: 331px;
    }

    .input-create-one-password{
        margin-left: -6px;
        width: 331px;
    }
  
  }