/* VISTA DOMICILIO LOCALIZACION */
.subtitle-localizacion {
    width: 525px;
    font-size: 20px;
    font-weight: bold;
    margin-left: -10px;
  }

  @media screen and (max-width:600px) {
    .subtitle-localizacion {
        width: 200px;
        font-size: 15px;
        font-weight: bold;
      }
  }